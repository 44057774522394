import React from 'react';

import { getDayOfWeekAsString, Restaurant, convertFramesToTexts, getOpeningHoursForDayOfWeek } from '@bestelleck/utils';

import './OpeningTimes.css';

interface OpeningTimesProps {
    restaurant: Restaurant;
}

export const OpeningTimes: React.FC<OpeningTimesProps> = ({ restaurant }) => {
    const hasDelivery = restaurant?.delivery ? true : false;
    const today = new Date();

    const openingTimes = restaurant.pickup?.days.map((day) => {
        let times;
        if (day.dayOfWeek === today.getDay()) {
            const scheduleDay = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.pickup?.days || [],
                    restaurant.pickup?.exceptions || [],
                    day.dayOfWeek,
                    today,
                ),
            );
            times = scheduleDay.map((text, index) => {
                return <div key={`${day.dayOfWeek}-${index}`}>{text}</div>;
            });
        } else {
            times = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.pickup?.days || [],
                    restaurant.pickup?.exceptions || [],
                    day.dayOfWeek,
                    today,
                ),
            ).map((frame, index) => <div key={`${day.dayOfWeek}-${index}`}>{frame}</div>);
        }

        return (
            <div key={`${day.dayOfWeek}pickup`} className="time">
                {getDayOfWeekAsString(day.dayOfWeek)} {times.length > 0 && <div>{times}</div>}
                {times.length === 0 && <div>Geschlossen</div>}
            </div>
        );
    });
    const deliveryTimes = restaurant.delivery?.days.map((day) => {
        let times;
        if (day.dayOfWeek === today.getDay()) {
            const scheduleDay = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.delivery?.days || [],
                    restaurant.delivery?.exceptions || [],
                    day.dayOfWeek,
                    today,
                ),
            );
            times = scheduleDay.map((text, index) => {
                return <div key={`${day.dayOfWeek}-${index}`}>{text}</div>;
            });
        } else {
            times = convertFramesToTexts(
                getOpeningHoursForDayOfWeek(
                    restaurant.delivery?.days || [],
                    restaurant.delivery?.exceptions || [],
                    day.dayOfWeek,
                    today,
                ),
            ).map((frame, index) => <div key={`${day.dayOfWeek}-${index}`}>{frame}</div>);
        }

        return (
            <div key={`${day.dayOfWeek}delivery`} className="time">
                {getDayOfWeekAsString(day.dayOfWeek)} {times.length > 0 && <div>{times}</div>}
                {times.length === 0 && <div>Geschlossen</div>}
            </div>
        );
    });

    return (
        <div className="root">
            <h2>Öffnungszeiten</h2>
            <hr className="divider"></hr>
            <div className="box">{openingTimes}</div>
            {hasDelivery && (
                <>
                    <h2>Lieferzeiten</h2>
                    <hr className="divider"></hr>
                    <div className="box">{deliveryTimes}</div>
                </>
            )}
        </div>
    );
};
