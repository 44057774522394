import { OrderType, Place } from '@bestelleck/utils';
import { createModel } from '@rematch/core';

import { RootModel } from './index';

export type OrderTypeState = {
    orderType: OrderType;
    needToCheck: boolean;
    hasBeenChecked: boolean;
    showDialog: boolean;
    place: Place;
};

export const orderType = createModel<RootModel>()({
    state: {
        orderType: OrderType.PickUp,
        needToCheck: false,
        hasBeenChecked: false,
        showDialog: false,
        place: {
            boundingbox: [],
            class: '',
            display_name: '',
            icon: '',
            importance: 0,
            lat: '',
            licence: '',
            lon: '',
            osm_id: 0,
            osm_type: '',
            place_id: 0,
            type: '',
            address: {
                country: '',
                country_code: '',
                county: '',
                house_number: '',
                postcode: '',
                state: '',
                suburb: '',
                city: '',
                neighbourhood: '',
                road: '',
                town: '',
                village: '',
            },
        },
    } as OrderTypeState,
    reducers: {
        setOrderType(state, { orderType }: { orderType: OrderType }) {
            return {
                ...state,
                orderType,
            };
        },
        // TODO can probably be removed
        checkOrderType(
            state,
            {
                previousRoute,
                deliveryAvailable,
                showDialog,
            }: { previousRoute: string; deliveryAvailable: boolean; showDialog: boolean },
        ) {
            const isDelivery = state.orderType === OrderType.Delivery;
            const needToCheckDelivery =
                !(previousRoute.includes('discover') || previousRoute.includes('checkout')) && !isDelivery;

            const needToCheck = needToCheckDelivery && deliveryAvailable && !state.hasBeenChecked;

            return {
                ...state,
                needToCheck,
                showDialog,
                hasBeenChecked: true && showDialog,
            };
        },
        // TODO can probably be removed
        setOrderTypeChecked(state) {
            return {
                ...state,
                hasBeenChecked: true,
            };
        },

        setPlace(state, { place }: { place: Place }) {
            return {
                ...state,
                place,
            };
        },
        setPlaceAndType(state, { place, orderType }: { place: Place; orderType: OrderType }) {
            return {
                ...state,
                place,
                orderType,
            };
        },
    },
});
