import { ScheduleDayFrame } from "./schedule-day-frame.interface";
import { ScheduleDay } from "./schedule-day.interface";
import { ScheduleException } from "./schedule-exception.interface";

export const MAX_MINUTES_OVERLAP = 6 * 60;
export const MAX_MINUTES_OF_DAY = 60 * 24;

export const getOpeningHoursForDayOfWeek = (
    openingScheduleDays: ScheduleDay[],
    exceptions: ScheduleException[],
    dayOfWeek: number,
    date: Date
): ScheduleDayFrame[] => {
    if (exceptions.length) {
        const exception = exceptions.find((exception) => {
            const exceptionDate = new Date(exception.date);
            return exceptionDate.toDateString() === date.toDateString() && exceptionDate.getDay() === dayOfWeek;
        });
        if (exception) {
            return exception.frames;
        }
    }

    const daySchedule = openingScheduleDays.find((openingScheduleDay) => openingScheduleDay.dayOfWeek === dayOfWeek);
    return daySchedule?.frames || [];
};

export const getOpeningHoursForDate = (
    openingScheduleDays: ScheduleDay[] = [],
    exceptions: ScheduleException[] = [],
    date: Date
): ScheduleDayFrame[] => {
    const dateInfo = getDateInfo(date, date.getDay());
    const openingFrames = getOpeningHoursForDayOfWeek(
        openingScheduleDays,
        exceptions,
        dateInfo.dayOfWeek,
        dateInfo.date
    );

    const hasOpeningHoursAfterTime = openingFrames.some(
        (frame) => frame.from > dateInfo.minutes || frame.to > dateInfo.minutes
    );
    if (!hasOpeningHoursAfterTime) {
        return [];
    }
    return openingFrames;
};

export const isRestaurantOpenOnDate = (
    openingScheduleDays: ScheduleDay[] = [],
    exceptions: ScheduleException[] = [],
    date: Date = new Date()
): boolean => {
    const dateInfo = getDateInfo(date, date.getDay());
    const openingFrames = getOpeningHoursForDate(openingScheduleDays, exceptions, date);
    return isOpenDuringFrames(dateInfo.minutes, openingFrames);
};

export const convertFramesToTexts = (frames: ScheduleDayFrame[]): string[] => {
    if (frames.length === 0) {
        return ["Geschlossen"];
    }

    return frames.map((frame) => `${convertToTimeString(frame.from)} - ${convertToTimeString(frame.to)}`);
};

export const convertDateToTime = (date: Date): number => {
    return date.getHours() * 60 + date.getMinutes();
};

export const convertToTimeString = (time: number): string => {
    const adjustedTime = time > 1440 ? time % 1440 : time;
    const hours = Math.floor(adjustedTime / 60);
    const minutes = adjustedTime % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

export const isOpenDuringFrames = (time: number, frames: ScheduleDayFrame[]): boolean => {
    return Boolean(frames.some((frame) => frame.from <= time && frame.to >= time));
};

export function getDayOfWeekAsString(dayIndex: number): string {
    return ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][dayIndex] || "";
}

export function getDateInfo(date: Date, day: number) {
    let minutes = convertDateToTime(date);
    let newDate = date;
    let dayOfWeek = day;

    if (minutes <= MAX_MINUTES_OVERLAP) {
        newDate = new Date(date);
        newDate.setDate(date.getDate() - 1);
        minutes += MAX_MINUTES_OF_DAY;
        dayOfWeek = (dayOfWeek + 6) % 7;
    }

    return {
        date: newDate,
        dayOfWeek,
        minutes,
    };
}
