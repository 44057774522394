import { Order, PaymentOption, Place, RestaurantDetail } from '@bestelleck/utils';
import { createModel } from '@rematch/core';

import { Location, ManualEntry } from '../../types/Geo';
import { RestaurantTags } from '../../types/Restaurant';
import { Contact, FilterAttributes, PaymentOptions } from '../types/app.types';

import { RootModel } from './index';

export type AppState = {
    selectedTags: string[];
    search: string;
    filters: {
        rating: string;
        payment: PaymentOption | 'none';
        minimumOrderValue: number;
        deliveryCost: number;
    };
    contact: Contact;
    order: Order | null;
    selectedRestaurant: RestaurantDetail | undefined;
    version: number;
    cartVersion: number;
    route: {
        previous: string;
        current: string;
    };
    hasRated: boolean;
    paymentOptions: PaymentOptions;
    selectedPaymentOption: PaymentOption;
};

export const app = createModel<RootModel>()({
    state: {
        selectedTags: [RestaurantTags.ALL],
        search: '',
        route: {
            previous: '',
            current: '',
        },
        selectedPaymentOption: PaymentOption.Cash,
        filters: {
            rating: 'none',
            payment: 'none',
            deliveryCost: -1,
            minimumOrderValue: -1,
        },
        selectedRestaurant: undefined,
        contact: {
            name: '',
            email: '',
            phone: '',
            address: '',
            city: undefined,
        },
        order: null,
        version: 0,
        cartVersion: 1,
        hasRated: false,
        paymentOptions: {
            google: false,
            apple: false,
            link: false,
        },
    } as AppState,
    reducers: {
        setRoute(state, route: string) {
            return {
                ...state,
                route: {
                    previous: state.route?.current || '',
                    current: route,
                },
            };
        },
        setPostalCode(
            state,
            {
                place,
                deliveryPlaces,
                manual,
            }: {
                place: Place;
                deliveryPlaces: Location[];
                manual: ManualEntry | undefined;
            },
        ) {
            return {
                ...state,
                location: {
                    place: place,
                    deliveryPlaces: deliveryPlaces,
                    manual: manual,
                },
            };
        },
        setFilter(state, { attribute, value }: { attribute: FilterAttributes; value: string | number }) {
            const filters = { ...(state.filters as any) };
            filters[attribute] = value;
            return {
                ...state,
                filters,
            };
        },
        setOrder(state, order: Order) {
            return {
                ...state,
                order,
            };
        },
        setSearch(state, query: string) {
            return {
                ...state,
                search: query,
            };
        },
        setContact(state, contact: Contact) {
            return {
                ...state,
                contact,
            };
        },
        setRated(state, hasRated: boolean) {
            return {
                ...state,
                hasRated,
            };
        },
        setPaymentMethods(state, paymentOptions: PaymentOptions) {
            return {
                ...state,
                paymentOptions,
            };
        },
        setSelectedPaymentMethod(state, paymentOption: PaymentOption) {
            return {
                ...state,
                selectedPaymentOption: paymentOption,
            };
        },
    },
});
