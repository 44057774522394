import React, { useState } from 'react';

import { DialogTitle } from '@bestelleck/shared';
import { Place, RestaurantDetail } from '@bestelleck/utils';
import { DialogContent, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';

import { Dispatch } from '../../store/store';
import { AddressComponent } from '../Address/Address';

import styles from './AddressDialog.module.scss';

type SelectOrderDialogProps = {
    open: boolean;
    handleClose: () => void;
    allowManualEntry?: boolean;
    restaurant?: RestaurantDetail;
};

const AddressDialog: React.FC<SelectOrderDialogProps> = ({ open, handleClose, restaurant }) => {
    const [tries, setTries] = useState(0);
    const dispatch = useDispatch<Dispatch>();
    const smallScreen = useMediaQuery('(max-width:750px)');

    return (
        <div>
            <Dialog
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="customized-dialog-title"
                id="selectOrderDialog"
                open={open}
                fullWidth
                fullScreen={smallScreen}
                maxWidth="sm"
                disableEnforceFocus
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        handleClose();
                    }}
                >
                    Adresse eingeben
                </DialogTitle>
                <DialogContent dividers>
                    <div className={styles.section} role="listbox">
                        <AddressComponent
                            restaurant={restaurant}
                            increaseTries={() => setTries(tries + 1)}
                            onSelect={(place: Place) => {
                                dispatch(dispatch.orderType.setPlace({ place }));
                                handleClose();
                            }}
                            showHelp={true}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddressDialog;
