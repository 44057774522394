import { DeliveryPlaceSimple } from '@bestelleck/utils';

import { baseUrl } from '../util/constants';
import { customFetch } from '../util/utils';

export const getRestaurantPlace = async (
    restaurantId: string,
    latitude: string,
    longitude: string,
): Promise<DeliveryPlaceSimple> => {
    const url = `${baseUrl}/restaurants/${restaurantId}/delivery/spatial?longitude=${longitude}&latitude=${latitude}`;
    return customFetch<DeliveryPlaceSimple>(url);
};
